main form {
    display: flex;
    flex-wrap: wrap;
}

main form .form-control {
    margin: 5px;
}

.MuiCircularProgress-colorPrimary {
    color: #176347 !important;
}
