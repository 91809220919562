.logged-in-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
}

.logged-in {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
}

.logged-in-container .MuiButton-outlined {
    border-color: white;
}

.logged-in-container .MuiButton-outlined .MuiButton-label {
    color: white;
}
