.download-container .MuiButton-text {
    border-color: #176347;
}

.download-container .MuiButton-label {
    color: #176347;
}

.download-container button {
    margin-left: 6px;
}

.data-container {
    height: 300px;
    width: 100%;
}
