.viewport {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-attachment: fixed;
    background-color: #176347;
    color: white;
    flex-direction: column;
    margin: -10px;
}

.loading-text {
    font-size: xx-small;
}
